import request from '@/utils/request'

// 一级接口-查询所有合作方账号
export function partner_account_query(data) {
    return request({
        url: '/platform_api/manage_api/partner_account/query',
        method: 'post',
        data: data
    })
}

// 一级接口-创造合作方   

export function partner_account_create(data) {
    return request({
        url: '/platform_api/manage_api/partner_account/create',
        method: 'post',
        data: data
    })
}

// 一级接口-更新合作方  
export function partner_account_update(data) {
    return request({
        url: '/platform_api/manage_api/partner_account/update',
        method: 'post',
        data: data
    })
}
// 一级接口-删除合作方  
export function partner_account_delete(data) {
    return request({
        url: '/platform_api/manage_api/partner_account/delete',
        method: 'post',
        data: data
    })
}
//二级接口-合作方中查询子账户
export function partner_children_account_query(data) {
    return request({
        url: '/platform_api/manage_api/partner_account/sub_account/query',
        method: 'post',
        data: data
    })
}

//二级接口-编辑合作方子账户
export function partner_children_account_query_edit(data) {
    return request({
        url: '/platform_api/manage_api/partner_account/sub_account/edit',
        method: 'post',
        data: data
    })
}

//二级接口-创造合作方子账户
export function partner_chirdren_account_create(data) {
    return request({
        url: '/platform_api/manage_api/partner_account/sub_account/create',
        method: 'post',
        data: data
    })
}