<template>
  <div>
    <infoHeader :content_name="'新增合作方主账户'" />

    <el-form ref="form" label-width="160px" :label-position="'left'">
      <el-form-item label="邮箱">
        <el-input v-model="form.email"></el-input>
      </el-form-item>

      <el-form-item label="密码">
        <el-input v-model="form.password"></el-input>
      </el-form-item>

      <el-form-item label="重复输入一遍密码">
        <el-input v-model="form.repassword"></el-input>
      </el-form-item>

      <el-form-item label="手机号">
        <el-input v-model="form.phone"></el-input>
      </el-form-item>

      <el-form-item label="姓名">
        <el-input v-model="form.name"></el-input>
      </el-form-item>

      <el-form-item label="">
        <el-button type="primary" @click="Submit">确认无误，立即创建</el-button>
      </el-form-item>
    </el-form>

    
  </div>
</template>

<script>
// 载入接口
// import { 接口函数 } from "@/api/接口函数定义文件";
import { partner_account_create } from "@/api/manage/business/partner_account/partner_account.js";

// 载入方法
// import { 方法名 } from "@/utils/js文件名";

export default {
  name: "start",
  data() {
    return {
      form: {
        email: null,
        password: null,
        repassword: null,
        phone: null,
        name: null,
      },
    };
  },
  components: {},
  created() {
    this.getQueryData();
  },
  methods: {
    Submit() {
      this.$confirm("是否确定增加", "确认信息", {
        distinguishCancelAndClose: true,
        confirmButtonText: "增加",
        cancelButtonText: "放弃增加",
      })
        .then(() => {
          partner_account_create(this.form).then((res) => {
            if (res.code == 200) {
              this.$message.success("成功添加");
              this.$router.push({ name: "login" });
            } else {
              this.$message.info(res.msg);
            }
          });
        })
        .catch((action) => {
          this.$message({
            type: "info",
            message: action === "cancel" ? "放弃增加" : "增加中",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
